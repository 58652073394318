<template>
  <a-breadcrumb :style="{ lineHeight: '40px' }" @click="goto(index)">
    <a-breadcrumb-item
      v-for="(item, index) in breadList"
      :key="item"
      :class="{ hoverItem: index !== breadList.length - 1 }"
      ><span @click="goto(index)">{{ item }}</span></a-breadcrumb-item
    >
  </a-breadcrumb>
</template>

<script>
export default {
  // props: {
  //   // 可通过mode来扩展面包屑的生成方式
  //   mode: {
  //     type: String,
  //     default: "default",
  //   },
  // },
  data() {
    return {
      breadList: [],
    };
  },
  created() {
    this.updateBread();
  },
  watch: {
    $route() {
      this.updateBread();
    },
  },
  methods: {
    updateBread() {
      let matched = this.$route.matched;
      this.breadList = matched[matched.length - 1].meta.breadcrumb.split("/");
    },
    goto(index) {
      const lastIndex = this.breadList.length - 1;
      if (index !== lastIndex) {
        this.$router.go(-(lastIndex - index));
      }
    },
  },
};
</script>

<style scoped>
.ant-breadcrumb >>> .hoverItem .ant-breadcrumb-link:hover {
  cursor: pointer;
  border-bottom: 1px solid rgba(0, 0, 0, 0.45);
}
</style>